import './App.css';
import MainForm from './components/MainForm';
import Footer from './components/Footer';
import {useState} from 'react';

function App() {
  const [lang, setLang] = useState('en');  
  const [result, setResult] = useState(0);
  const [units, setUnits] = useState('metric');
  // option for currency
  const options = [{value: 'eur', text: 'EUR'}, {value: 'usd', text: 'USD'}, {value: 'czk', text: 'CZK'}, {value: 'gbp', text: 'GBP'}];

  const [selectedCurr, setSelectedCurr] = useState(options[0].text);

  const handleChange = e => {
    //let selected = options.filter(option=>option.value===e.target.value);
    setSelectedCurr(e.target.value);
  }

  const changeLang = (e) => {    
    setLang(e.target.name);
    //console.log(lang);   
  } 
  
  // get data fom child
  const getResult = (result) => {
    setResult(result);
    console.log(result);
  }

  const getUnits = (units) => {
    setUnits(units);
  }    
  if (lang==='en'){
    return (
      <div className="App">
        
        <h3>Trip costs calculator</h3>
        <div className='lang-currency-group'>
          <div className='langs'>
                  <button onClick={changeLang} name='en'>English</button>
                  <button onClick={changeLang} name='sk'>Slovak</button></div>
                  <select value={selectedCurr} onChange={handleChange} id='currency'>
                    {options.map(option=>(
                      <option key={option.value} value={option.text}>{option.text}</option>
                    ))                 
                    }
                  </select>
              </div>
        <MainForm lang = {lang} getResult={getResult} getUnits={getUnits} currency={selectedCurr}/>
        <h4>The price for 1 passanger: {result} {selectedCurr}</h4>
        <p>Selected units: {units}</p>
        <p>Selected currency: {selectedCurr}</p>
        <Footer lang={lang}/>
      </div>
    );
  }
  if (lang==='sk'){
    return (
      <div className="App">
        
        <h3>Výpočet nákladov na cestujúcich</h3>
        <div className='lang-currency-group'>
          <div className='langs'>
                  <button onClick={changeLang} name='en'>Anglicky</button>
                  <button onClick={changeLang} name='sk'>Slovensky</button></div>
                  <select value={selectedCurr} onChange={handleChange} id='currency'>
                    {options.map(option=>(
                      <option key={option.value} value={option.text}>{option.text}</option>
                    ))                 
                    }
                  </select>
              </div>
        <MainForm lang = {lang} getResult={getResult} getUnits={getUnits} currency={selectedCurr}/>
        <h4>Cena na 1 cestujúceho: {result} {selectedCurr}</h4>
        <p>Zvolené jednotky: {lang==='sk'&& units==='metric'?'metrické':'imperiálne'}</p>
        <p>Zvolená mena: {selectedCurr}</p>
        <Footer lang={lang}/>
      </div>
    );
  }
  
}

export default App;
