import {useState} from 'react';


export default function MainForm({lang, getResult, getUnits, currency}){
    const [units, setUnits] = useState({
        unit: 'metric' 
    });
    const unitOptions = [{
    type: 'metric',
    distances: 'kms',
    distance: 'km',
    avg: 'l/100 km',
    volume: 'l'
    }, 
    {type: 'imperial',
    distances: 'mi',
    distance: 'mi',
    avg: 'US gal/100 mi',
    volume: 'US gal'
}];
    const [unitsDetails, setUnitsDetails] = useState({
        type: 'metric',
        distances: 'kms',
        distance: 'km',
        avg: 'l/100 km',
        volume: 'l'
        });  
    const [inputs, setInputs] = useState({
        distance: 0,
        price: 0,
        consumption: 0,
        passangers: 0        
    });
    const [result, setResult] = useState(0);
    const [warning, setWarning] = useState('');
    

    const onChange = (e)=>{
        let input = e.target.value;
        input = input.replace(',', '.');
        if (+input){
            setInputs({...inputs, [e.target.name] : input});
            console.log(e.target.name);
            
            setWarning('');
            e.target.style = {};
            
            
        }else{
            setWarning('The input must be a number!!!');
            e.target.style.backgroundColor = 'rgba(255,0,0,0.3)';
            e.target.style.border = '1px solid red';
            e.target.style.borderRadius = '3px';
            e.target.style.fontWeight = 'normal';
            e.target.value = '';
            if(lang==='en'){
                e.target.placeholder='The input must be a number!!!';
            }
            if(lang==='sk'){
                e.target.placeholder='Údaj musí byť číslo!!!';
            }
            
            
            
        }
        
        //console.log(e.target.name);
        
    }
    const onSubmit = (e) => {
        e.preventDefault();
        let result = ((inputs.distance/100)*inputs.consumption*inputs.price)/inputs.passangers;
        //console.log(inputs.distance, inputs.price, inputs.consumption, inputs.passangers);
        if(+result){
            getResult(result);
        } else {
            getResult('Error');
        }

    }

    const onChangeUnits = (e) => {
        setUnits({unit: e.target.value});
        const selectedDetails = unitOptions.filter(option => option.type===e.target.value);
        //console.log({unit: e.target.value});
        setUnitsDetails(...selectedDetails);
        getUnits(e.target.value);
    }

    
    if (lang==='en'){
        return (
            <div className='form-container'>
                <form className='main-form' onSubmit={onSubmit}>            
                <div className='form-group'>
                    <label forhtml='distance' id='distance'>Driven distance in {unitsDetails.distances}</label>
                    <input type='text' name='distance' onChange={onChange} ></input>
                </div>
                <div className='form-group'>
                    <label forhtml='price'>Price {currency}/{unitsDetails.volume}</label>
                    <input type='text' name='price' onChange={onChange} ></input>
                </div>
                <div className='form-group'>
                    <label forhtml='consumption'>Average Consumption {unitsDetails.avg}</label>
                    <input type='text' name='consumption' onChange={onChange} ></input>
                </div>
                <div className='form-group'>
                    <label forhtml='passangers' >Number of Passangers</label>
                    <input type='text' name='passangers' onChange={onChange} ></input>
                </div>
                <fieldset className='radio-group'>
                    <legend>Units: </legend>
                    <div className='inner-radio-group'>
                    <label forhtml='metric'>Metric</label>
                    <input type='radio' name='units' value='metric' checked={units.unit === 'metric'} onChange={onChangeUnits}/></div>
                    <div className='inner-radio-group'>
                    <label forhtml='imperial'>Imperial</label>
                    <input type='radio' name='units' value='imperial' checked={units.unit === 'imperial'} onChange={onChangeUnits}/>
                    </div>
                </fieldset>
                
                <input type='submit' value='Calculate'></input>
                </form>                
            </div>
        );
    } else {
        return(
            <div className='form-container'>
                <form className='main-form' onSubmit={onSubmit}>            
                <div className='form-group'>
                    <label forhtml='distance' id='distance'>Prejdená vzdialenosť v {unitsDetails.distance}</label>
                    <input type='text' name='distance' onChange={onChange} ></input>
                </div>
                <div className='form-group'>
                    <label forhtml='price'>Cena {currency}/{unitsDetails.volume}</label>
                    <input type='text' name='price' onChange={onChange} ></input>
                </div>
                <div className='form-group'>
                    <label forhtml='consumption'>Priemerná spotreba {unitsDetails.avg}</label>
                    <input type='text' name='consumption' onChange={onChange} ></input>
                </div>
                <div className='form-group'>
                    <label forhtml='passangers' >Počet cestujúcich</label>
                    <input type='text' name='passangers' onChange={onChange} ></input>
                </div>
                <fieldset className='radio-group'>
                    <legend>Jednotky: </legend>
                    <div className='inner-radio-group'>
                    <label forhtml='metric'>Metrické</label>
                    <input type='radio' name='units' value='metric' checked={units.unit === 'metric'} onChange={onChangeUnits}/></div>
                    <div className='inner-radio-group'>
                    <label forhtml='imperial'>Imperiálne</label>
                    <input type='radio' name='units' value='imperial' checked={units.unit === 'imperial'} onChange={onChangeUnits}/>
                    </div>
                </fieldset>
                
                <input type='submit' value='Vypočítať'></input>
                </form>
            </div>
        )
    }

    
}