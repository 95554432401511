export default function Footer ({lang}){
    if (lang==="en") {
        return(
        
            <p className="footer">Created with ReactJS. ©Marek Krajnak</p>
        );
    } 
    
    if (lang==="sk") {
        return(
        
            <p className="footer">Vytvorené s ReactJS. ©Marek Krajňák</p>
        )
    }
    
}